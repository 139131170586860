import React, { useState } from 'react'
import styled from 'styled-components'
import { Column, Row } from 'components/common/layout'
import { ScoutLandingCtaBtn } from 'components/scouts/landing/cta-button'
import { LinearText } from 'components/scouts/landing/text'
import { BaseText } from 'components/common/text'
import { useIsDesktop, useObserver } from 'utils/hooks'

export const ScoutLandingEndCta: React.FC<{
  setIsButtonVisible: (isVisible: boolean) => void
}> = ({ setIsButtonVisible }) => {
  const isDesktop = useIsDesktop()
  const [isVisible, setIsVisible] = useState(false)
  const observingContent = useObserver<HTMLDivElement>((entries) => {
    if (entries[0].isIntersecting) {
      setIsButtonVisible(true)
      !isVisible && setIsVisible(true)
    } else {
      setIsButtonVisible(false)
    }
  })
  return (
    <Main>
      <div ref={observingContent} />
      {isVisible && (
        <Container isDesktop={isDesktop}>
          <MainText>
            {`그룹바이에서\n매력적인 스카우트 제안을 `}
            <br />
            {`받아보세요.`}
          </MainText>
          <SubText>{`VC가 투자한 성공 보장 스타트업에 합류해보세요.\n나에게 딱 맞는 이직 조건을 제안해줄\n스타트업들이 기다리고 있어요.`}</SubText>
          <ScoutLandingCtaBtn />
        </Container>
      )}
    </Main>
  )
}

const SubText = styled(BaseText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    display: none;
  }
  line-height: 1.5;
  white-space: pre-wrap;
  color: ${(p) => p.theme.color.black4};
`

const Container = styled(Column)<{ isDesktop: boolean }>`
  @media ${(p) => p.theme.deviceSize.mobile} {
    padding: 0 16px;
    width: 100%;
    text-align: start;
    align-items: flex-start;
  }
  gap: 32px;
  text-align: center;
  align-items: center;
  opacity: 1;
  position: absolute;
  top: ${(p) => (p.isDesktop ? 80 : 120)}px;
  @keyframes finishFadeIn {
    0% {
      top: ${(p) => (p.isDesktop ? 120 : 200)}px;
      opacity: 0;
    }
    100% {
      top: ${(p) => (p.isDesktop ? 80 : 120)}px;
      opacity: 1;
    }
  }
  animation: finishFadeIn 1000ms 1;
`

const MainText = styled(LinearText)`
  @media ${(p) => p.theme.deviceSize.desktop} {
    br {
      display: none;
    }
  }
  @media ${(p) => p.theme.deviceSize.mobile} {
    font-size: 32px;
  }
  font-size: 40px;
  line-height: 1.3;
  white-space: pre-wrap;
`

const Main = styled(Row)`
  @media ${(p) => p.theme.deviceSize.mobile} {
    height: 555px;
  }
  height: 358px;
  position: relative;
  justify-content: center;
  background: linear-gradient(85deg, #ffffff 2.65%, #e0f8ee 95.98%);
`
